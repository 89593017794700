import React, { useState } from 'react'
import { Tabs, Tab } from 'react-bootstrap'

import UserList from '../redux_features/users/OrganizationUsersList'
import StickyHeadTable from '../redux_features/devices/DevicesList'
import 'bootstrap/dist/css/bootstrap.min.css'
import { VehiclesList } from '../redux_features/vehicles/VehicleList'
import DriversList from '../redux_features/drivers/DriversList'
function Settings() {
  const [Units, setUnits] = useState('Miles')
  const [selectedTab, setSelectedTab] = useState(
    () => localStorage.getItem('organizationEntitiesTab') || 'Vehicles',
  )
  const handleTabSelect = (key: string | null) => {
    const newTab = key || 'Vehicles'
    setSelectedTab(newTab)
    localStorage.setItem('organizationEntitiesTab', newTab)
  }

  return (
    <>
      <Tabs
        id="uncontrolled-tab-example"
        className="mb-3"
        activeKey={selectedTab}
        onSelect={handleTabSelect}
      >
        <Tab eventKey="Vehicles" title="Vehicles">
          <VehiclesList />
        </Tab>
        <Tab eventKey="Drivers" title="Drivers">
          <DriversList />
        </Tab>

        <Tab eventKey="Devices" title="Devices">
          <StickyHeadTable />
        </Tab>
        <Tab eventKey="Users" title="Users">
          <UserList />
        </Tab>
      </Tabs>
    </>
  )
}

export default Settings
