import { TextField, Box, Button, List, ListItem } from '@mui/material'

import { useState } from 'react'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'
import PasswordValidatorComponent from '../redux_features/session/Passwordvalidator'

import { RootState } from '../app/store'
import { useSelector } from 'react-redux'

import { userLogout } from '../redux_features/session/cogAccount'
import { deauthenticate } from '../redux_features/session/sessionSlice'
import { useAppDispatch } from '../app/hooks'

function SecurityPage() {
  const [OldPassword, setOldPassword] = useState('')
  const [newpassword, setnewPassword] = useState('')
  const [reppassword, setreppassword] = useState('')
  const [changePassword, setonChangePassword] = useState(false)
  const dispatch = useAppDispatch()
  const user = useSelector((state: RootState) => state.session.userdata.coguser)
  const ChangePasswordSucess = (e: any) => {
    if (e) alert('error during password change' + e)
    else {
      alert('Password changes sucessfully')

      userLogout()
      dispatch(deauthenticate('test')) // TODO  good opractice to clean the whole redux store..!
    }
  }
  //TODO review why is not always executed
  const changePasswordcmd = () => {
    if (user) {
      user.changePassword(OldPassword, newpassword, ChangePasswordSucess)
    } else alert('no user info')
  }
  return (
    <div>
      {/* <Container maxWidth="xs"> */}
      {/* <CssBaseline /> */}
      {!changePassword && (
        <Button
          variant="contained"
          onClick={() => {
            setonChangePassword(true)
          }}
        >
          Change Password
          <CachedOutlinedIcon />
        </Button>
      )}
      {changePassword && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
          }}
        >
          <TextField
            size="small"
            margin="normal"
            required
            id="oldPassword"
            label="Old Password"
            name="oldpassword"
            autoComplete="password"
            autoFocus
            value={OldPassword}
            onChange={(event) => setOldPassword(event.target.value)}
            sx={{ maxWidth: 200 }}
          />
          <TextField
            size="small"
            margin="normal"
            required
            id="NewPassword"
            label="New Password"
            name="New Password"
            autoComplete="password"
            autoFocus
            value={newpassword}
            onChange={(event) => setnewPassword(event.target.value)}
            sx={{ maxWidth: 200 }}
          />
          <TextField
            size="small"
            margin="normal"
            required
            id="repNewPassword"
            label="Repeat New  Password"
            name="repNewPassword"
            autoComplete="password"
            autoFocus
            value={reppassword}
            onChange={(event) => setreppassword(event.target.value)}
            sx={{ maxWidth: 200 }}
          />

          <div>
            <Button onMouseDown={changePasswordcmd} sx={{ maxWidth: 200 }}>
              {' '}
              Save
            </Button>
            <Button
              onClick={() => {
                setonChangePassword(false)
              }}
              sx={{ maxWidth: 100 }}
            >
              {' '}
              Cancel
            </Button>
          </div>
          <PasswordValidatorComponent password={newpassword} />
        </Box>
      )}

      {/* </Container> */}
    </div>
  )
}

export default SecurityPage
