import React from 'react'
import CustomSwipeableDrawer from '../CustomSwipeableDrawer/CustomSwipeableDrawer'
import VehiclesTab from './VehiclesTab'

import { MapControllerViewProps } from './mapTypes'

const MobileView: React.FC<MapControllerViewProps> = ({
  state,
  handlers,
  mapProps,
}) => {
  return (
    <>
      <CustomSwipeableDrawer
        content={
          <VehiclesTab
            onCardClick={handlers.handleCardClick}
            onHistoryClick={handlers.historyClick}
            onInfoClick={handlers.infoClick}
            state={state}
          />
        }
      />
    </>
  )
}

export default MobileView
