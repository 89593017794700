import React from 'react'
import { Box, Avatar, Typography, Grid, TextField, Button } from '@mui/material'
import NumbersIcon from '@mui/icons-material/Numbers'
import { CognitoUserPool } from 'amazon-cognito-identity-js'
import { confirmUser } from './cogAccount'

interface ConfirmationCodeFormProps {
  userEmail: string
  userPool: CognitoUserPool
  onSuccess: () => void
}

//TODO: add code resend cognito function
export const ConfirmationCodeForm: React.FC<ConfirmationCodeFormProps> = ({
  userEmail,
  userPool,
  onSuccess,
}) => {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const code = String(data.get('code'))

    confirmUser(userEmail, code, userPool)
      .then((cognitoUser) => {
        console.log('User confirmed:', cognitoUser)
        onSuccess()
      })
      .catch((error) => {
        console.error('Confirmation failed:', error)
        alert('User could not be confirmed, please try again')
      })
  }

  return (
    <Box component="form" noValidate onSubmit={handleSubmit}>
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <NumbersIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Please enter the code sent to your email
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            autoComplete="code"
            name="code"
            id="code"
            label="Code"
            autoFocus
          />
        </Grid>
      </Grid>
      <Button type="submit" variant="contained" sx={{ mt: 2 }}>
        Confirm
      </Button>
    </Box>
  )
}

export default ConfirmationCodeForm
