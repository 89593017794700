import * as React from 'react'

import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'

import IconButton from '@mui/material/IconButton'

import Typography from '@mui/material/Typography'
import { Key } from 'react'
import { useSelector } from 'react-redux'
import { deviceUpdated, fetchDevices, selectAllDevices } from './deviceSlice'
import { RootState } from '../../app/store'
import { Device, secureCompany, secureDevice, Vehicle } from '../../api/types'
import { useAppDispatch } from '../../app/hooks'

import {
  Avatar,
  Button,
  CircularProgress,
  Container,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material'
import { selectAllVehicles } from '../vehicles/vehicleSlice'
import useWindowSize from '../../customHooks/useWindowSize'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import SearchIcon from '@mui/icons-material/Search'

import { ColumnDefinitionType } from '../../components/GenericTable1'

const DeviceRow = (item: Device) => {
  const dispatch = useAppDispatch()
  const sessionToken = useSelector(
    (state: RootState) => state.session.userdata.accesstoken,
  )
  const organizationId = useSelector(
    (state: RootState) => state.session.userdata.organizationId,
  )

  const [onEditRow, setOpen] = React.useState(false)
  const [name, setName] = React.useState(item.name)
  const vehicles = useSelector(selectAllVehicles)

  const onNameChanged = (e: any) => {
    setName(e.target.value)
  }
  const onUpdateDeviceClicked = async () => {
    let tempDevice: Device = { ...item }
    tempDevice.name = name

    if (tempDevice) {
      const obj: secureDevice = {
        device: tempDevice,
        token: sessionToken,
        organizationId,
      }

      try {
        await dispatch(deviceUpdated(obj))
      } catch (err) {
      } finally {
        // setOpen(false)
      }
    }
  }

  return (
    <React.Fragment>
      {!onEditRow && (
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar
                alt={item.name}
                src="https://moreycorp.com/wp-content/uploads/2022/08/Morey-Logo-h_RGB.svg"
                //  src="morey_logo_only.png"
                sx={{ backgroundColor: '#dfe2e7' }}
              />
              <Typography color={'#3099a3'}>{item.name}</Typography>
            </Stack>
          </TableCell>

          <TableCell>
            {
              vehicles.find((vehic: Vehicle) => vehic?.device?.id === item.id)
                ?.name
            }
          </TableCell>
          <TableCell>{item.inventoryReference.deviceIdentifier}</TableCell>
          <TableCell>{item.inventoryReference.iccid}</TableCell>
          <TableCell>
            <Typography color={'#3099a3'}>{item.id}</Typography>
          </TableCell>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!onEditRow)}
            >
              {/* {onEditRow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} */}

              <EditOutlinedIcon sx={{ marginRight: 2 }} />
            </IconButton>
          </TableCell>
        </TableRow>
      )}
      {onEditRow && (
        <TableRow
          style={{
            borderBottom: 'unset',
            height: 120,
            backgroundColor: '#f8fcff',
          }}
        >
          <TableCell>
            <TextField
              size="small"
              id="outlined-name"
              label="Device Name"
              sx={{ minWidth: 180 }}
              value={name}
              onChange={onNameChanged}
            />
          </TableCell>

          <TableCell>
            <Tooltip title="Go to Vehicle Settings to edit">
              <Typography>
                {' '}
                {
                  vehicles.find(
                    (vehic: Vehicle) => vehic?.device?.id === item.id,
                  )?.name
                }
              </Typography>
            </Tooltip>
          </TableCell>

          <TableCell>
            <div>
              <Button
                variant="outlined"
                sx={{ backgroundColor: '#036c86', color: '#ffff' }}
                onClick={onUpdateDeviceClicked}
              >
                Save
              </Button>
            </div>
            <div>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
            </div>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  )
}

const columns: ColumnDefinitionType<Device, keyof Device>[] = [
  {
    key: 'name',
    header: 'Name',
    width: 150,
  },
  {
    key: 'createdAt',
    header: 'createdAt',
  },
  {
    key: 'lastModified',
    header: 'lastModified',
  },
]

const TableHeaders = () => (
  <TableHead>
    <TableRow>
      <TableCell>Device Name</TableCell>
      <TableCell>Vehicle Name</TableCell>
      <TableCell>Device Identifier</TableCell>
      <TableCell>ICCID</TableCell>
      <TableCell>Device ID</TableCell>
      <TableCell>Actions</TableCell>
    </TableRow>
  </TableHead>
)
export default function StickyHeadTable() {
  const dispatch = useAppDispatch()
  const [page, setPage] = React.useState(0)
  const [namesearch, setNameSearch] = React.useState('')

  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const error = useSelector((state: RootState) => state.devices.error)
  const sessionToken = useSelector(
    (state: RootState) => state.session.userdata.accesstoken,
  )
  const organizationId = useSelector(
    (state: RootState) => state.session.userdata.organizationId,
  )
  const deviceStatus = useSelector((state: RootState) => state.devices.status)
  const devices: Device[] = useSelector(selectAllDevices)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }
  const { width, height } = useWindowSize()

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  const onSearchNameChanged = (e: any) => {
    setNameSearch(e.target.value)
  }
  React.useEffect(() => {
    if (deviceStatus === 'idle') {
      //add code statuts in
      console.log('hello22')
      const secCompany: secureCompany = {
        token: sessionToken,
        organizationId: organizationId,
      }
      dispatch(fetchDevices(secCompany))
    }
  }, [deviceStatus, dispatch])

  let content

  if (deviceStatus === 'loading') {
    //content = <Spinner text="loading..." />
    content = <CircularProgress />
  } else if (deviceStatus === 'succeeded') {
    content = devices
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .filter((val: Device) => {
        if (namesearch === '') {
          return val
        } else if (val.name.toLowerCase().includes(namesearch.toLowerCase())) {
          return val
        } else return null
      })
      .map((device: Device, index: Key) => (
        <DeviceRow key={device.id} {...device} />
      ))
  } else if (deviceStatus === 'error') {
    content = <div>{error.toString()}</div>
  }

  return (
    <>
      <Container
        sx={{
          minWidth: 800,
          maxHeight: useWindowSize().height,
          overflow: 'scroll',
        }}
      >
        <Typography variant="h4" gutterBottom>
          Devices
        </Typography>

        <TablePagination
          rowsPerPageOptions={[5, 25, 100]}
          component="div"
          count={devices.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <TextField
          size="small"
          label="Search Device"
          onChange={onSearchNameChanged}
        >
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        </TextField>

        <Paper
          sx={{
            height: height - height * 0.3,
            width: '100%',
            overflow: 'scroll',
          }}
        >
          <TableContainer>
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{ verticalAlign: 'baseline' }}
            >
              <TableHeaders />

              <TableBody style={{ overflowY: 'auto' }}>{content}</TableBody>
            </Table>
          </TableContainer>

          {/* <TablePagination
            rowsPerPageOptions={[5, 25, 100]}
            component="div"
            count={devices.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Paper>
      </Container>
    </>
  )
}
