import React from 'react'
import VehicleHistoryController from './VehicleHistoryController'
import { VehicleInfo } from '../VehicleInfo'
import RightDrawer from '../RightDrawer'
import { MapControllerViewProps } from './mapTypes'

const RightDrawerContent: React.FC<MapControllerViewProps> = ({
  state,
  handlers,
  mapProps,
}) => {
  if (!state.openRightDrawer) return null

  return (
    <>
      {state.rightDrawerMode === 'history' && state.vehicleId && (
        <RightDrawer close={handlers.closeRightDrawer} anchor="right">
          <VehicleHistoryController
            handleCloseButton={handlers.closeRightDrawer}
            device={state.vehicleId}
            map={mapProps.map}
          />
        </RightDrawer>
      )}
      {state.rightDrawerMode === 'liveinfo' && (
        <VehicleInfo
          deviceid={state.vehicleId}
          close={handlers.closeRightDrawer}
        />
      )}
    </>
  )
}

export default RightDrawerContent
