import React from 'react'
import { LastKnownData } from '../../api/types'
import { useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import VehiclesControllersDev from '../VehiclesController_dev'
import { MapControllerState } from './mapTypes'

interface VehiclesTabProps {
  onCardClick: (item: LastKnownData) => void
  onHistoryClick: (deviceId: string) => void
  onInfoClick: (deviceId: string) => void
  state: MapControllerState
}

const VehiclesTab: React.FC<VehiclesTabProps> = ({
  onCardClick,
  onHistoryClick,
  onInfoClick,
  state,
}) => {
  return (
    <div className="vehicles-tab">
      <VehiclesControllersDev
        onclickHandler={onCardClick}
        historyClick={onHistoryClick}
        infoClick={onInfoClick}
        state={state}
      />
    </div>
  )
}

export default VehiclesTab
