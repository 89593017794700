import axios from 'axios'
import { Dict, GeoLocation, LastKnownData } from './types'

const API_URL = process.env.REACT_APP_API_URL!

const f = (r: any): Dict => JSON.parse(r)

export const getGeoLocations = async (
  organizationId: string,
  deviceId: string | null | undefined,
  startDate: Date,
  endDate: Date,
  sessionToken: string,
): Promise<GeoLocation[]> => {
  //
  //

  const response = await axios.get<any>(
    `${API_URL}/${organizationId}/geolocations`,
    {
      params: { startDate, endDate, deviceId, organizationId },

      transformResponse: f,
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + sessionToken,
      },
    },
  )

  //
  return response.data['result']['geolocationData'] //this works but probably needs to be tuned in  API?
}

export const getLatestGeoLocations = async (
  organizationId: string | null,
  sessionToken: string,
): Promise<LastKnownData[]> => {
  const response = await axios.get<any>(
    `${API_URL}/${organizationId}/devices/latest`,
    {
      //   params: { organizationId },

      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + sessionToken,
      },
    },
  )
  //review
  console.log(response.data['result'])
  const transformedItems = response.data['result'].map((item: any) => ({
    ...item,
    latitude: parseFloat(item.latitude),
    longitude: parseFloat(item.longitude),
  }))
  console.log(transformedItems)
  //
  //return response.data['Items']
  return transformedItems
}
